<!-- Contact Us Start -->

<section class="section" id="contact">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Entrer <span class="text-warning"> en contact</span></h3>
                    <p class="text-muted">Si vous avez un message ou des commentaires? N'hésitez pas à nous contacter
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <div class="contact-address">
                    <h4 class="text-dark mb-4">Informations de contact</h4>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="contact-address">
                            <h5 class="text-dark mb-3 f-16">Adresse:</h5>
                            <p class="text-muted f-15">4 Av. Laurent Cély, 92600 Asnières-sur-Seine, France</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 offset-lg-1">
                <div class="custom-form mt-4 mt-lg-0">
                    <div id="message"></div>
                    <form method="post" [formGroup]="form" (ngSubmit)="form.valid && onSubmit()" name="contact-form"
                        id="contact-form">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group app-label">
                                    <label for="name">Nom</label>
                                    <input formControlName="name" name="name" id="name" type="text" class="form-control"
                                        placeholder="Entrez votre nom..">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group app-label">
                                    <label for="email">Email</label>
                                    <input formControlName="email" name="email" id="email" type="email"
                                        class="form-control" placeholder="Entrer votre Email..">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group app-label">
                                    <label for="comments">Message</label>
                                    <textarea name="comments" formControlName="message" id="comments" rows="3"
                                        class="form-control" placeholder="Entrez le message.."></textarea>
                                </div>
                            </div>

                            <ngx-recaptcha2 style="margin-left: 20px; padding-bottom: 25px;" #captchaElem [siteKey]="siteKey"
                            [type]="type"

                            formControlName="recaptcha">
                            </ngx-recaptcha2>


                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <button [disabled]="!form.valid" type="submit" id="submit" name="send"
                                    class="btn btn-warning">Envoyer<i class="mdi mdi-telegram ml-2"></i></button>
                                <div id="simple-msg"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Contact Us End -->