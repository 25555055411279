<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
    id="navbar">
    <div class="container">
        <!-- LOGO -->
        <a class="navbar-brand logo" href="">
            <img src="assets/images/logo-dark.png" alt="" class="" height="50">
        </a>
        <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li class="nav-item" [ngClass]="{'active':currentSection === ''}">
                    <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">ACCUEIL</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
                    <a [ngxScrollTo]="'#services'" href="javascript: void(0)" class="nav-link"
                        [ngxScrollToOffset]="2">EXPERTISES</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'blog'}">
                    <a [ngxScrollTo]="'#blog'" href="https://expertnow.medium.com/" class="nav-link"
                        [ngxScrollToOffset]="2">BLOG</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
                    <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link"
                        [ngxScrollToOffset]="2">A PROPOS</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
                    <a [ngxScrollTo]="'#contact'" href="javascript: void(0);" class="nav-link"
                        [ngxScrollToOffset]="2">CONTACT</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
    <!-- Hero Start -->
    <section class="hero-1-bg position-relative bg-light d-flex align-items-center" id="home">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="hero-title pr-lg-5">
                        <h1 class="hero-1-title mb-4 font-weight-normal line-height-1_4">Services &
                            conseils IT avec <span class="text-primary font-weight-medium">ExpertNow</span></h1>
                        <p class="text-muted mb-4 pb-2">ExpertNow propose une large gamme de services de conseil pour
                            servir une variété d'entreprises et de clients. Que vous soyez une petite entreprise locale
                            ou une multinationale, nous pouvons vous aider à atteindre de nouveaux niveaux de réussite.
                            Contactez-nous dès aujourd'hui pour en savoir plus sur nos services et sur ce que notre
                            équipe peut faire pour vous.</p>
                        <a [ngxScrollTo]="'#services'" href="javascript: void(0)" class="btn btn-warning">Trouver comment<span
                                class="ml-2 right-icon">&#8594;</span></a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mt-5 mt-lg-0">
                        <img src="assets/images/hero-1-img.png" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Hero End -->
    <app-services></app-services>
    <app-features></app-features>
    <!--  <app-clients></app-clients>  -->

    <app-contact></app-contact>
    <app-footer></app-footer>
</div>
