<!-- Pre-loader -->
<div id="preloader" *ngIf="isLoading">
  <div id="status">
    <div class="spinner">
      <div class="dot1"></div>
      <div class="dot2"></div>
    </div>
  </div>
</div>
<!-- End Preloader-->
<router-outlet></router-outlet>
