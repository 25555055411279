<!-- Testimonial Start -->
<section class="section bg-light" id="clients">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <div class="title text-center mb-5">
            <h3 class="font-weight-normal text-dark">Notre <span class="text-warning">Equipe</span></h3>
            <p class="text-muted">nous avons une équipe pleine d'experts dans tous les domaines</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <h3 class="font-weight-normal mt-3 line-height-1_4">Nous présentons ici <span
              class="font-weight-medium text-warning">nos héros</span></h3>
          <div class="testi-border my-4"></div>
        </div>
        <div class="col-lg-8 testi-carousel">
          <owl-carousel-o [options]="testimonialOptions">
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">Roberta Guevara</h6>
                        <p class="text-muted mb-0 f-14">Web Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
            
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">"Ahmed GARGOURI"</h6>
                        <p class="text-muted mb-0 f-14">Résponsable marketing</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
         
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">HOUSSEM</h6>
                        <p class="text-muted mb-0 f-14">Web Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">EYA </h6>
                        <p class="text-muted mb-0 f-14">Résponsable RH</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">dorra</h6>
                        <p class="text-muted mb-0 f-14">Devops Engineer </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- Testimonial End -->
  
  
