<!-- footer Start -->
<section class="footer" style="background-color:#003268;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="mb-4">
                    <a href=""><img src="assets/images/whitelogo.png" alt="" class="logo-dark" height="50" /></a>
                    <p class="text-muted mt-4 mb-2">contact@expertnow.fr</p>
                    <h6 class="text-muted font-weight-normal">06 41 05 54 13 </h6>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-md-4">
                        <h6 style="color: #FFF;">Services</h6>
                        <ul class="list-unstyled company-sub-menu">
                            <li><a [ngxScrollTo]="'scloud'" href="javascript: void(0);" [ngxScrollToOffset]="-70">Solutions cloud</a></li>
                            <li><a [ngxScrollTo]="'cybersec'" href="javascript: void(0);" [ngxScrollToOffset]="-70">Cybersécurité</a></li>
                            <li><a [ngxScrollTo]="'sinfo'" href="javascript: void(0);" [ngxScrollToOffset]="-70">Services informatiques</a></li>
                            <li><a [ngxScrollTo]="'sreseau'" href="javascript: void(0);" [ngxScrollToOffset]="-70">Solutions de mise en réseau</a></li>
                            <li><a [ngxScrollTo]="'tvalid'" href="javascript: void(0);" [ngxScrollToOffset]="-70">Test & Validation</a></li>
                            <li><a [ngxScrollTo]="'atech'" href="javascript: void(0);" [ngxScrollToOffset]="-70">Assistance technique</a></li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <h6 style="color: #FFF;">À propos de nous</h6>
                        <ul class="list-unstyled company-sub-menu">
                            <li><a [ngxScrollTo]="'#contact'" href="javascript: void(0);" [ngxScrollToOffset]="10">Nous
                                    contacter</a></li>
                            <li><a href="https://expertnow.medium.com/">Blog</a></li>
                            <li style="display: none;"><a href="">Politique de confidentialité</a></li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <h6 style="color: #FFF;"> Notre Addresse</h6>
                        <p class="text-muted f-14">4 Av. Laurent Cély, 92600 Asnières-sur-Seine, France</p>
                        <h6 class="text-muted pb-2">Email: contact@expertnow.fr</h6>
                        <ul class="list-unstyled footer-social-list mt-4">
                            <li class="list-inline-item"><a
                                    href="https://www.linkedin.com/company/expertnow-consulting/"><i
                                        class="mdi mdi-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="text-center text-muted">
                    <p class="mb-0 f-15"> {{currentYear}} © ExpertNow</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- footer End -->
