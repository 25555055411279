<!-- Service Start -->
<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Nos <span class="text-warning">​EXPERTISES</span></h3>
                    <p class="text-muted">Nous vous apportons notre expertise et un service de qualité et nous serons
                        votre partenaire de confiance dans vos projets autour des sujets ci dessous.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div id="scloud" class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-cloud service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Solutions cloud</h5>
                    <p class="text-muted service-subtitle mb-4">Face aux enjeux de la transformation numérique,
                        ExpertNow vous propose des services Cloud innovants et adaptés à vos besoins, en sélectionnant
                        parmi les technologies leaders du marché les plus aptes à couvrir vos besoins et répondre à vos
                        exigences.
                    </p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>
            <div id="cybersec" class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-science service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Cybersécurité</h5>
                    <p class="text-muted service-subtitle mb-4">La cybersécurité est au cœur de la préservation 
                        de votre entreprise dans le monde numérique d'aujourd'hui. Chez ExpertNow, nous comprenons 
                        les enjeux critiques de la protection de vos données et de votre réputation en ligne. 
                        Nos experts en cybersécurité sont dédiés à vous fournir des solutions sur mesure 
                        qui anticipent et neutralisent les menaces numériques. Avec notre approche proactive, 
                        nous garantissons que votre entreprise reste en sécurité et opérationnelle, 
                        vous permettant de vous concentrer sur la croissance de vos activités en toute confiance. 
                        Contactez-nous dès aujourd'hui pour une évaluation gratuite de votre posture de sécurité 
                        et découvrez comment ExpertNow peut renforcer votre défense en ligne.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>
            <div id="sinfo" class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-light service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Services informatiques</h5>
                    <p class="text-muted service-subtitle mb-4">-Notre démarche part de la compréhension de vos besoins,
                        passe par la définition de la solution nécessaire à y répondre et analyse les impacts de sa mise
                        en œuvre et de son maintien en conditions opérationnelles.
                        Notre équipe va construire la solution, l’architecturer et la dimensionner, elle définira le
                        niveau de service nécessaire à sa mise en œuvre.
                        Nous travaillons dans un processus de conseil permanent, afin de nous assurer que vous ayez la
                        solution qui répond le mieux à vos besoins, et pas seulement dans le cadre parfois trop strict,
                        d’un cahier des charges.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>
            <div id="sreseau" class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-graph1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Solutions de mise en réseau</h5>
                    <p class="text-muted service-subtitle mb-4">ExpertNow dispose d’une expertise unique sur le marché
                        garantissant un accompagnement et des engagements forts sur l’intégralité des infrastructures du
                        système d’information ( des environnements serveurs, stockage et réseaux)</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>
            <div id="tvalid" class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-display1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Test & Validation</h5>
                    <p class="text-muted service-subtitle mb-4">ExpertNow a constitué une équipe spécialisée en
                        qualification et recette.
                        Certifiés ISTQB, maitrisant les normes de la qualité logicielle nos consultants QA et
                        automaticiens des tests vous accompagnent tout au long du processus de création de vos produits
                        (validation avant lancement, maintenance, update) pour garantir le bon fonctionnement de vos
                        applications Web mobile et API</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>
            <div id="atech" class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-headphones service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Assistance technique</h5>
                    <p class="text-muted service-subtitle mb-4">Nos équipes supervisent votre infrastructure
                        informatique et assurent son maintien en condition opérationnelle.
                        L’objectif est de garantir un fonctionnement permanent de votre SI et vous accompagner dans les
                        évolutions à venir.
                        Nos équipes techniques assurent le maintien en condition opérationnelle de vos postes, vos
                        serveurs, votre réseau et vos firewall.
                    </p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- Service End -->
