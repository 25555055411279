import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ContactService } from '../data.service/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  type = "image"
  siteKey='6LdcY2ggAAAAAByp95GWQ1bjrCv-v6yVQoRfTYX7'
  form = new FormGroup({
    name: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    recaptcha: new FormControl('', Validators.required),
  });

  constructor(private contact: ContactService) { }

  ngOnInit(): void {
  }
  onSubmit() {
    console.log(this.form.value)
    const emailData = {
      "txt_email" : this.form.value.email,
      "name" :this.form.value.name,
      "txt_message": this.form.value.message,
    }
    this.contact.SendEmail(emailData).subscribe(data => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Votre message a été envoyé avec succès',
        showConfirmButton: false,
        timer: 1500
      })
      this.form.reset();
    })

    
  }

}
